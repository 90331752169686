//STYLES FROM BOOTSTRAP.BUILD

//colors
$white: #fff;
$black: #000;
$dark: #1c1a32;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$primary: #d11f7a;
$secondary: #2b2940;
$success: #4cb25b;
$danger: #d23a4b;
$yellow: #e6b341;
$info: #9e9e9e;
$light: #d9d9d9;
//body
$body-bg: $dark;
$body-color: $white;

//typography
$font-size-base: 0.75rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.33333;
$h5-font-size: $font-size-base * 1.166666;
$h6-font-size: $font-size-base;
$lead-font-size: $font-size-base * 1.166666;
$lead-font-weight: 400;
$small-font-size: 91.6666%;

//cards
$card-bg: $dark;
$card-border-radius: 4px;
$card-cap-bg: $secondary;
$card-border-color: $secondary;
$card-img-overlay-padding: 1.2rem;

//buttons
$btn-focus-box-shadow: none;
$btn-font-size: 0.75rem;
$btn-font-size-sm: 0.75rem;
$btn-font-weight: 500;
$btn-border-radius: 1.25rem;
$btn-border-radius-sm: 1rem;
$btn-border-radius-lg: 1.5rem;
$btn-padding-x: 1.875rem;
$btn-padding-y: 0.575rem;
$btn-padding-x-sm: 1.25rem;
$btn-padding-y-sm: 0.375rem;
$btn-padding-x-lg: 1.875rem;
$btn-padding-y-lg: 0.8rem;
$btn-disabled-opacity: 0.5;

//links
$btn-link-disabled-color: $gray-300;
$link-color: $primary;
$link-hover-color: lighten($link-color, 10%);
$link-hover-decoration: none;

//custom controls
$custom-checkbox-indicator-indeterminate-bg: lighten($primary, 10%);
$custom-control-gutter: 0.75rem;
$custom-control-indicator-border-width: 1px;
$custom-control-indicator-size: 1rem;
$custom-control-indicator-bg: $body-bg;
$custom-control-indicator-border-color: #4f5271;
$custom-control-indicator-checked-border-color: $primary;
$custom-control-indicator-active-bg: lighten($primary, 10%);
$custom-control-indicator-active-border-color: $primary;
$custom-control-label-disabled-color: $white;
$custom-switch-width: $custom-control-indicator-size * 1.75;
//$custom-switch-indicator-border-radius: $custom-control-indicator-size / 2;
$custom-switch-indicator-size: 0.7rem;
$custom-select-focus-box-shadow: none;
$custom-select-indicator-color: $white;

//progress bar
$progress-height: 6px;
$progress-bg: rgba(209, 230, 243, 0.32);
$progress-border-radius: 40px;
$progress-bar-color: $primary;
$progress-bar-bg: $primary;

//label
$label-margin-bottom: 0.375rem;

//input
$input-border-radius: 0.25rem;
$input-bg: $secondary;
$input-color: $white;
$input-border-color: $secondary;
$input-focus-border-color: $white;
$input-focus-box-shadow: none;
$input-group-addon-color: $white;
$input-group-addon-bg: $secondary;
$input-padding-x: 0.75rem;
$input-padding-y: 0.5rem;
$input-disabled-bg: $secondary;
$input-btn-focus-box-shadow: none;

//border, border-radius
$border-color: $secondary;
$border-radius-sm: 0.125rem;
$border-radius: 0.25rem;
$border-radius-lg: 0.375rem;

//nav
$nav-tabs-link-active-color: $white;
$nav-pills-link-active-bg: $primary;
$nav-link-disabled-color: $gray-800;
$nav-tabs-border-radius: 0;
$navbar-light-color: $secondary;

//dropdown
$dropdown-bg: $secondary;
$dropdown-header-color: $white;
$dropdown-link-hover-color: $white;
$dropdown-link-color: $white;
$dropdown-link-hover-bg: $primary;
$dropdown-divider-bg: $secondary;

//badge
$badge-font-size: 85%;
$badge-padding-y: 0.5em;

//jumbotron
$jumbotron-bg: $dark;

//??
$component-active-bg: $primary;

//pagination
$pagination-color: $white;
$pagination-bg: $secondary;
$pagination-border-color: none;
$pagination-active-bg: $primary;
$pagination-hover-bg: #383650;
$pagination-hover-color: $white;
$pagination-disabled-bg: rgba($secondary, 0.3);
$pagination-disabled-color: rgba($white, 0.3);
$pagination-disabled-border-color: $gray-800;

//list
$list-group-hover-bg: #383650;
$list-group-bg: $dark;
$list-group-active-bg: $secondary;
$list-group-border-radius: 0;
$list-group-border-color: none;
$list-group-active-bg: #383650;
$list-group-action-active-bg: #383650;
$list-group-action-color: #d1e6f3;

//modal
$modal-content-bg: $dark;

// $close-color: $primary;
$close-font-size: $h5-font-size;
$close-font-weight: 400;
$close-text-shadow: none;

//breadcrumb
$breadcrumb-bg: $secondary;
$breadcrumb-divider-color: $white;
$breadcrumb-active-color: $white;

//hr divider
$hr-border-color: $secondary;
$hr-border-width: 2px;

//slider
$custom-range-track-height: 0.375rem;
$custom-range-track-bg: rgba(209, 230, 243, 0.32);
$custom-range-track-border-radius: 2.5rem;
$custom-range-track-box-shadow: 1rem 1rem 1rem $primary;
$custom-range-thumb-bg: $primary;
$custom-range-thumb-focus-box-shadow: none;
$custom-range-thumb-active-bg: lighten($primary, 10%);

//popover
$popover-bg: #f1ede4;
$popover-header-bg: #f1ede4;
$popover-body-color: $black;
$popover-header-color: $black;

//alerts
$alert-padding-x: 1rem;
